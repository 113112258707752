body,
html {
  height: unset !important;

  .scroll-disabled {
    @media (max-width: 768px) {
      overflow: hidden;
    }
  }
}

.ant-form-item-explain {
  padding: 8px 0;
}

.ant-breadcrumb {
  > span:last-child a {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45) !important;
  }

  .highlight {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

.ant-badge-round > sup {
  min-width: 17px;
  height: 17px;
  padding: 0;
  font-size: 10px;
  line-height: 19px;
  border-radius: 50%;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quill {
  &.hidden {
    .ql-editor {
      background: #fffbe6;
    }
  }

  .ql-editor {
    height: 150px;
  }

  &.expanded {
    .ql-editor {
      height: auto;
      max-height: 500px;
      min-height: 200px;
    }
  }

  .ql-toolbar {
    &.ql-snow {
      background: #fafafa;
      .ql-formats {
        margin: 0;
      }
    }
  }
}

@keyframes background-blink {
  0% {
    background-color: #fff;
  }
  20% {
    background-color: $highlight;
  }
  80% {
    background-color: $highlight;
  }
  100% {
    background-color: #fff;
  }
}
.ant-comment {
  padding: 10px;
  border-bottom: 1px solid $gray-100;
  scroll-margin-top: 70px;
  @media (max-width: 768px) {
    padding: 4px;
  }
}

.ant-comment,
.notes,
.ticket-page__description {
  &--linked {
    animation-name: background-blink;
    animation-duration: 3s;
    animation-delay: 0.1s;
  }

  a {
    color: #1b2ec4 !important;
    text-decoration: underline !important;
  }
  p {
    margin-bottom: 0 !important;
  }

  img {
    max-width: 100%;
    overflow: hidden;
  }
  .ql-syntax {
    box-shadow: 0px 0px 1px 0px black;
    padding: 10px;
    margin: 10px;
    //background: #333;
    //color: #7ca57c;
    background: #f3f3f3;
    color: black;
    font-size: 12px;
    max-width: 100%;
    font-family: "Source Code Pro", monospace;
  }

  blockquote {
    border-left: 5px solid rgba(220, 220, 220, 1);
    padding-left: 10px;
    margin-bottom: 4px;
  }

  .ant-comment-avatar img {
    width: 100%;
    height: 100%;
    border-radius: unset;
  }

  .ant-comment-content-author {
    align-items: center;
  }

  &.new {
    background: #ade1e624;
    border: 1px solid #ade1e624;
  }

  .ant-comment-content-detail > div {
    max-width: 100%;
  }
}

.ant-avatar-group {
  > a:not(:first-child) {
    margin-left: -8px;
  }
}

.ant-avatar-group-popover {
  a {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.hoverable {
  cursor: pointer;
}
.fc-day-grid-event > .fc-content {
  white-space: normal;
}
.fc-event-time,
.fc-event-title {
  display: inline-block;
  overflow-wrap: break-word;
  width: 100%;
  max-width: 100%;
  overflow: visible;
  white-space: normal;
}

.fc-title {
  display: inline-block;
  overflow-wrap: break-word;
  width: 100%;
  max-width: 100%;
  overflow: visible;
  white-space: normal;
}

.fc {
  //&-unselectable {
  //  -webkit-user-select: unset !important;
  //  user-select: unset !important;
  //  -webkit-touch-callout: unset !important;
  //}
  &-daygrid-event {
    white-space: normal !important;
    margin: 8px 0;
  }
  //
  //&-daygrid-block-event {
  //
  //  .fc-event-title {
  //    overflow-wrap: break-word !important;
  //  }
  //}
  //
  //&-h-event .fc-event-title {
  //  overflow-wrap: break-word !important;
  //
  //}

  a {
    color: black;
  }

  &-daygrid-day-frame {
    position: relative;
    min-height: calc((100vh - 160px) / 6) !important;
  }

  &-day-other {
    background: #efefef;
  }
}

.ant-drawer {
  z-index: 1101 !important;
}

.break-words {
  max-width: 100%;
  overflow-wrap: break-word;
}
.ant-space-break-words {
  max-width: 100%;

  .ant-space-item {
    max-width: 100%;
    span {
      overflow-wrap: break-word;
    }
  }
}

.autosave {
  &--icon {
    color: $secondary;
  }
  &--text {
    color: $secondary;
  }
}

.ant-popover-message-title {
  max-width: 300px;
}

.ant-comment {
  .comment-editor label {
    width: 100%;
    height: 24px;
  }
}

.ReactModal__Overlay--after-open {
  z-index: 10000 !important;
}

ReactModal__Body--open {
  overflow: hidden;
}

.ant-notification-topRight {
  .ant-notification-notice-icon-success {
    color: $successAlert;
  }

  @media (max-width: 768px) {
    left: 0px;
    top: 0 !important;
    width: 100%;
    height: 20px;
    padding: 0;
    margin: 0;

    .ant-notification-notice {
      width: 100%;
      max-width: 100%;

      text-align: center;
      padding: 0;
      margin: 0;

      &-success {
        background: $successAlert;
      }
      &-error {
        background: $error;
      }
    }

    .ant-notification-notice-message {
      font-size: 14px !important;
      color: white;
      margin: 0 !important;
      padding: 0;
    }
    .ant-notification-notice-icon,
    .ant-notification-notice-close {
      display: none;
    }
  }

  //with class mobile

  .ant-notification-notice.mobile {
    width: 100%;
    max-width: 100%;

    text-align: center;
    padding: 0;
    margin: 0;

    &-success {
      background: $successAlert;
    }
    &-error {
      background: $error;
    }
    .ant-notification-notice-message {
      font-size: 14px !important;
      color: white;
      margin: 0 !important;
      padding: 0;
    }
    .ant-notification-notice-icon,
    .ant-notification-notice-close {
      display: none;
    }
  }
}

.ant-notification-topLeft {
  left: 0px;
  top: 0 !important;
  width: 100%;
  height: 20px;
  margin: 0;

  .ant-notification-notice {
    width: 100%;
    max-width: 100%;

    text-align: center;
    padding: 0;
    margin: 0;

    &-success {
      background: $successAlert;
    }
    &-error {
      background: $error;
    }
  }
  .ant-notification-notice-message {
    font-size: 14px !important;
    color: white;
    margin: 0 !important;
    padding: 0;
  }
  .ant-notification-notice-icon,
  .ant-notification-notice-close {
    display: none;
  }
}
