.search {
  &__wrapper {
    width: 700px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__container {
    width: 100%;
    position: relative;

    > .ant-space-item:nth-of-type(1) {
      width: 100%;
    }
  }

  &__input {
    max-width: 300px;
    &--open {
      max-width: 100%;
    }
    &--mobile {
      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__box {
    padding: 24px;
    background: white;
    width: calc(100% - 2px);
    margin-left: 1px;
    position: absolute;
    //top: 50px;
    top: 40px;
    box-shadow: 0 4px 8px rgba($gray-500, 0.25), 0 0 1px rgba($gray-500, 0.25);
    max-height: calc(100vh - 64px);
    overflow-x: unset;
    overflow-y: auto;

    @media (max-width: 768px) {
      top: 58px;
    }

    > .ant-space-item {
      width: 100%;
    }
  }
}
